/**
 * Head
 *
 * @author HDNET GmbH & Co. KG
 */
// ua.detect.js
navigator.detect = (function() {
	var html = document.documentElement, os, vendor, version, classes;
	var n = navigator.appName, ua = navigator.userAgent.toLowerCase(),
		platform = navigator.platform.toString().toLowerCase(), temp;
	var m = ua.match(
		/(opera|chrome|safari|firefox|msie|trident)\/?\s*(\.?\d+(\.\d+)*)/i);

	if (m && (temp = ua.match(/version\/([\.\d]+)/i)) !== null) {
		m[2] = temp[1];
	}
	m = m ? [m[1], m[2]] : [n, navigator.appVersion, '-?'];

	if (!os) {
		platform.search('win') >= 0 ? os = 'win' : 'os2';
		platform.search('mac') >= 0 ? os = 'mac' : 'os2';
		ua.search('iphone') >= 0 ? os = 'idevice iphone' : 'os2';
		ua.search('ipad') >= 0 ? os = 'idevice ipad' : 'os2';
		platform.search('linux') >= 0 ? os = 'linux' : 'os2';
		ua.search('android') >= 0 ? os = 'android' : 'os2';
	}

	vendor = m[0].toLowerCase();
	version = m[1].substr(0, m[1].indexOf('.'));
	classes = os + ' ' + vendor + ' ' + vendor + version;

	html.className = classes;
})();

// head-config.js
/**
 * head-config.js
 *
 * @author HDNET GmbH & Co. KG
 * @version 1.0
 * @description Set of variables which needs to be available for scripts within
 *     head.js
 */
// Lazysizes defaults override
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.loadMode = 1;

// Parsley validation defaults override
window.ParsleyConfig = {
	successClass: 'has-success',
	errorClass: 'has-danger',
	// specify where parsley error-success classes are set
	classHandler: function(el) {
		return el.$element.closest('.form-group');
	},
	// specify the container for error messages to put in
	errorsContainer: function(el) {
		var $err = el.$element.closest('.form-group');
		return $err;
	},
	errorsWrapper: '<ul class="form-control-feedback"></ul>',
	errorTemplate: '<li></li>',
};

// shared-config.js
window.SharedConfiguration = {
	'breakpoints': {
		'xsmall': '20em',
		'small': '30em',
		'medium': '48em',
		'large': '64em',
		'xlarge': '75em',
		'super': '120em',
	},
};

// default.js
/**
 * default.js file
 *
 * @author HDNET GmbH & Co. KG
 * @version 7.6
 */

/**
 * decrypt helper function
 *
 * @param {number} n
 * @param {number} start
 * @param {number} end
 * @param {number} offset
 */
window.decryptCharcode = function(n, start, end, offset) {
	n = n + offset;
	if (offset > 0 && n > end) {
		n = start + (n - end - 1);
	} else if (offset < 0 && n < start) {
		n = end - (start - n - 1);
	}
	return String.fromCharCode(n);
};

/**
 * Decrypt string
 *
 * @param enc
 * @param offset
 * @returns {string}
 */
window.decryptString = function(enc, offset) {
	var dec = '';
	var len = enc.length;
	for (var i = 0; i < len; i++) {
		var n = enc.charCodeAt(i);
		if (n >= 0x2B && n <= 0x3A) {
			dec += decryptCharcode(n, 0x2B, 0x3A, offset);	// 0-9 . , - + / :
		} else if (n >= 0x40 && n <= 0x5A) {
			dec += decryptCharcode(n, 0x40, 0x5A, offset);	// A-Z @
		} else if (n >= 0x61 && n <= 0x7A) {
			dec += decryptCharcode(n, 0x61, 0x7A, offset);	// a-z
		} else {
			dec += enc.charAt(i);
		}
	}
	return dec;
};

/**
 * Decrypt spam-protected emails
 *
 * @param s
 */
window.linkTo_UnCryptMailto = function(s) {
	location.href = decryptString(s, 3);
};

/**
 * Open images in a standard popup
 *
 * @param url
 * @param winName
 * @param winParams
 */
window.openPic = function(url, winName, winParams) {
	var theWindow = window.open(url, winName, winParams);
	if (theWindow) {
		theWindow.focus();
	}
};
